import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './styles.css';

export default function Footer(props) {
  const ano = new Date();

  return (
    <footer className={props.fixed ? 'footer-fixed' : 'footer pt-2 pb-2'}>
      <Container className="d-flex justify-content-center align-items-center text-center h-100">
        <Row className="d-flex justify-content-center align-items-center text-center w-100">
          <Col xs={12} md={3} className="pb-2 pt-2">
            <span>
              <i className="far fa-registered"></i>
              {` Todos os direitos reservados -
          ${ano.getFullYear()} - Smart Fidelidade - Experiencia SW`}
            </span>
          </Col>
          <Col xs={12} md={3} className="pb-2 pt-2">
            <span>
              <i className="fas fa-mobile"></i> celular{' '}
              <a href="tel:+551533596265" style={{ color: '#fff' }}>
                (15) 3359-6265
              </a>
            </span>
            <br />
            <br />
            <span>
              <i class="fab fa-whatsapp"></i> WhatsApp{' '}
              <a href="https://wa.me/5511971331362" style={{ color: '#fff' }}>
                (11) 97133-1362
              </a>
            </span>
          </Col>
          <Col xs={12} md={3} className="pb-2 pt-2">
            <span>
              <i className="fas fa-envelope"></i> sac.clubepropintor@geniale.net
            </span>
          </Col>
          <Col xs={12} md={3} className="pb-2 pt-2">
            <span>
              <Link to="/politica-privacidade">
                <span className="politica-privacidade">
                  Política de Privacidade Smart
                </span>
              </Link>
            </span>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}
